<template>
  <div>
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <img src="../../assets/img/image1.jpg" alt="" />
    <div class="good-container">
      <van-cell-group inset>
        <van-cell>
          <template #title>
            <h3 style="text-align: left">新冠预防中药</h3>
          </template>
          <template #label>
            <div class="good-button">
              <span><span class="good-price">￥8.00</span> / 副</span>
              <van-button type="info" @click="handleBuy"> 购 买 </van-button>
            </div>
          </template>
        </van-cell>
        <div class="good-desc">
          <div>推荐方介绍</div>
          <div>【药品名称】: 新冠预防中药</div>
          <div>
            【推荐方】: 连翘 10g，芦根 15g，虎杖 15g， 炒牛蒡子 6g，桑叶
            6g，荆芥 10g，生甘草 6g
          </div>
          <div>
            【用法用量】: 每天一付，早晚各一次（饭后约
            40分钟），温服，每次200毫升，连服五天。
          </div>
        </div>
      </van-cell-group>
    </div>
    <van-dialog
      class="dialog"
      v-model="overflowShow1"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h4>确认信息</h4>
      </template>
      <br />
      <div class="van-field-container">
        <van-field name="num" label="数量">
          <template #input>
            <van-stepper v-model="num" integer />
          </template>
        </van-field>
        <van-field name="price" label="价格">
          <template #input> ￥{{ price }} </template>
        </van-field>
        <van-field
          v-model="remark"
          name="订单备注"
          label="订单备注"
          placeholder="请输入备注信息"
        />
      </div>
      <span class="icon-close" @click="overflowShow1 = false"
        ><van-icon name="cross"
      /></span>
      <br />
      <div style="margin: 16px">
        <van-button round block type="info" @click="handlePay"
          >立即支付￥{{ price }}</van-button
        >
        <br />
      </div>
    </van-dialog>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
      @confirm="handleConfirm"
    >
      <template #title>
        <h3>提示</h3>
      </template>
      <template #default>
        <p class="dialog-message">{{ tip_notice }}</p>
        <van-button class="cancle-btn" @click="showDialog = false"
          >关闭</van-button
        >
        <van-button type="primary" class="confirm-btn1" @click="handleConfirm"
          >确认</van-button
        >
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "traditionalMedical",
  data() {
    return {
      showPopup: false, // 弹出层
      personId: "",
      chooseItem: "",
      list: [],
      overflowShow1: false,
      num: 1,
      remark: "",
      showDialog: false,
      tip_notice: "",
      appId: "",
      timestamp: "",
      nonce: "",
      packageName: "",
      signType: "",
      signature: "",
    };
  },
  computed: {
    price() {
      return (this.num * 8).toFixed(2);
    },
  },
  methods: {
    /**
     * 获取微信公众平台的code
     */
    getAliPayCode() {
      // 判断是否有微信code
      let sessionCode = sessionStorage.getItem("AliPayCode");
      if (!sessionCode) {
        // 没有则进行授权
        const redirectUrl = `${domain}index.html%23%2FtraditionalMedical`;
        this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
        if (this.code == null || this.code === "" || this.code == undefined) {
          // 如果没有code，则去请求
          window.location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
          );
        } else {
          sessionStorage.setItem("AliPayCode", this.code);
          // 获取openid
          this.getOpenID(this.code);
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getList();
        return;
      }
      let postData = {
        code: code,
      };
      this.$http
        .post("/api/login/wx", postData)
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          sessionStorage.setItem("userid", res.data.id);
          sessionStorage.setItem("openid", res.data.userId);
          this.getList();
        })
        .catch((err) => {
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("AliPayCode", "");
          sessionStorage.setItem("userid", "");
          sessionStorage.setItem("openid", "");
          // let redirectUrl =`${domain}index.html%23%2Fquery`;
          // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`;
        });
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getMedicineJudge();
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            Toast.clear();
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            sessionStorage.setItem(
              "CurrentJZPerson",
              JSON.stringify(this.chooseItem)
            );
            this.isHasPatient();
          }
        })
        .then(() => {
          this.getMedicineJudge();
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.showPopup = true;
        return;
      }
    },
    getMedicineJudge() {
      let openid = sessionStorage.getItem("openid");
      let param = {
        openid: openid,
        name: this.chooseItem.name,
        idNumber: this.chooseItem.idNumber,
      };
      this.$http.get("/api/medicine/judge", param).then((result) => {
        if (result.status != 204) {
          this.showDialog = true;
          this.tip_notice =
            "您当天已购买了新冠预防中药，请到“个人中心-我的订单”完成支付！";
        }
      });
    },
    /**
     * 点击对话框确定按钮
     */
    handleConfirm() {
      this.showDialog = false;
      this.$router.push("/traditionalMedicalRecord");
    },
    handleBuy() {
      let openid = sessionStorage.getItem("openid");
      let param = {
        openid: openid,
        name: this.chooseItem.name,
        idNumber: this.chooseItem.idNumber,
      };
      this.$http.get("/api/medicine/judge", param).then((result) => {
        if (result.status != 204) {
          this.showDialog = true;
          this.tip_notice =
            "您当天已购买了新冠预防中药，请到“个人中心-我的订单”完成支付！";
        } else {
          this.overflowShow1 = true;
        }
      });
    },
    /**
     * 立即支付
     */
    handlePay() {
      let postData = {
        openid: sessionStorage.getItem("openid"),
        name: this.chooseItem.name,
        healthId: this.chooseItem.healthId,
        idNumber: this.chooseItem.idNumber,
        phone: this.chooseItem.phone,
        price: 8,
        number: this.num,
        amount: this.price,
        remark: this.remark,
      };
      this.$http.post("/api/medicine", postData).then((result) => {
        let order_id = result.data;
        this.$http.get("/api/medicine/wx", { id: order_id }).then((res) => {
          this.appId = res.data.appId;
          this.timestamp = res.data.timeStamp;
          this.nonce = res.data.nonceStr;
          this.packageName = res.data.package;
          this.signType = res.data.signType;
          this.signature = res.data.paySign;
          this.isWeChat();
        });
      });
    },
    isWeChat() {
      let self = this;
      if (typeof WeixinJSBridge == "undefined") {
        self.onBridgeReady();
        Toast({ message: "请在微信客户端进行支付操作" });
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            self.onBridgeReady(),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", self.onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", self.onBridgeReady());
        }
      } else {
        self.onBridgeReady();
      }
    },
    onBridgeReady() {
      let self = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: self.appId, //公众号名称，由商户传入
          timeStamp: self.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: self.nonce, //随机串
          package: self.packageName,
          signType: self.signType, //微信签名方式：
          paySign: self.signature, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            self.$router.push({
              path: "/traditionalMedicalRecord",
            });
          }
        }
      );
    },
  },
  mounted() {
    this.getAliPayCode();
  },
};
</script>

<style scoped>
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}

.good-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.good-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.good-price {
  font-size: 16px;
  color: #cc006b;
}
.good-desc {
  text-align: left;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
}
.dialog-title {
  margin-bottom: 0.3rem;
}
.dialog-message {
  font-size: 0.45rem;
  line-height: 26px;
  margin-bottom: 5px;
  padding: 0.5rem 0.8rem;
}
.confirm-btn1 {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.icon-close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.van-field-container {
  margin: 0 15px;
}
</style>
